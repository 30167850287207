exports.components = {
  "component---src-pages-about-us-app-jsx": () => import("./../../../src/pages/about-us-app.jsx" /* webpackChunkName: "component---src-pages-about-us-app-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-buat-cv-jsx": () => import("./../../../src/pages/buat-cv.jsx" /* webpackChunkName: "component---src-pages-buat-cv-jsx" */),
  "component---src-pages-contact-us-app-jsx": () => import("./../../../src/pages/contact-us-app.jsx" /* webpackChunkName: "component---src-pages-contact-us-app-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cv-app-jsx": () => import("./../../../src/pages/cv-app.jsx" /* webpackChunkName: "component---src-pages-cv-app-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pasang-loker-2-jsx": () => import("./../../../src/pages/pasang-loker-2.jsx" /* webpackChunkName: "component---src-pages-pasang-loker-2-jsx" */),
  "component---src-pages-privacypolicy-app-jsx": () => import("./../../../src/pages/privacypolicy-app.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-app-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */),
  "component---src-pages-termsandconditions-app-jsx": () => import("./../../../src/pages/termsandconditions-app.jsx" /* webpackChunkName: "component---src-pages-termsandconditions-app-jsx" */),
  "component---src-pages-termsandconditions-jsx": () => import("./../../../src/pages/termsandconditions.jsx" /* webpackChunkName: "component---src-pages-termsandconditions-jsx" */),
  "component---src-widgets-blog-article-index-jsx": () => import("./../../../src/widgets/@blog/article/index.jsx" /* webpackChunkName: "component---src-widgets-blog-article-index-jsx" */)
}

